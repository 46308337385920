import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import {Project} from '../../components/views';

const IndexPage = () => (
  <Layout>
    <SEO title="Bodhi" />
    <Project name="isleworthResidences" />
  </Layout>
);

export default IndexPage;
